<template>
  <div>
    <a href="#" v-if="!date" @click.prevent="createDate()" class="flix-btn">{{
      $t("time.immediately")
    }}</a>
    <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-5" v-else>
      <a href="#" class="flix-btn" @click.prevent="deleteDate()">
        <icon id="menu-close"
      /></a>
      <datePicker :time="date" :callback="setDate" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    datePicker() {
      return import("@/components/default/form/datePicker");
    }
  },
  props: {},
  data() {
    return {
      date: this.$store.state.business.unsaved.start
    };
  },
  computed: {},
  mounted() {},
  methods: {
    deleteDate() {
      this.date = "";
      this.setSave();
    },
    createDate() {
      var d = new Date();
      d.setDate(d.getDate() + 1);
      d.setMinutes(0);
      this.date =
        d.getFullYear() +
        "-" +
        this.getNullsBefore(d.getMonth() + 1) +
        "-" +
        this.getNullsBefore(d.getDate()) +
        " " +
        this.getNullsBefore(d.getHours()) +
        ":" +
        this.getNullsBefore(d.getMinutes());
      this.setSave();
    },
    setDate(d) {
      this.date = d;
      this.setSave();
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.start = this.date;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
